<template>
  <div class="min-h-screen flex flex-col items-center justify-center bg-gray-950 text-white">

    <div class="text-center">
      <h1 class="text-5xl gradient-text mb-6">Site en préparation...</h1>
      <p class="max-w-3xl text-lg text-gray-300 mx-auto mb-8">
        En parallèle de mes études et de plusieurs projets annexes, je consacre chaque moment disponible à la
        construction de ce site.
        Malgré un emploi du temps chargé, je m’efforce de créer une plateforme à la hauteur de mes ambitions et de vos
        attentes.
        Revenez bientôt pour découvrir le fruit de ce travail passionné.
      </p>

      <div class="mt-8">
        <img class="h-10 w-auto mx-auto opacity-80 hover:opacity-100 transition duration-300" :src="currentLogo" alt="Logo" />
      </div>
    </div>
  </div>
</template>

<script setup>
import darkLogo from '@/assets/Favicon.png';

const currentLogo = darkLogo;
</script>

<style scoped>
.gradient-text {
  background: linear-gradient(to right, #0ea5e9, #bae6fd, #ffffff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

img {
  filter: drop-shadow(0px 4px 6px rgba(0, 0, 0, 0.2));
}
</style>
