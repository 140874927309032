import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import './assets/tailwind.css';
import './fonts/fonts.css';
import i18n from './i18n';

// Créer une instance de l'application
const app = createApp(App);

// Utiliser les plugins nécessaires
app.use(i18n);
app.use(router);

// Monter l'application
app.mount('#app');
