import { createI18n } from 'vue-i18n';

// Traductions pour différentes langues
const messages = {
  en: {
    header: {
      about: 'About Me',
      projects: 'Projects',
      skills: 'Skills',
      contact: 'Contact',
      documentation: 'Documentation',
    },
    button: 'Switch to Dark Mode',
  },
  fr: {
    header: {
      about: 'À propos de moi',
      projects: 'Projets',
      skills: 'Compétences',
      contact: 'Contact',
      documentation: 'Documentation',
    },
    button: 'Passer en mode sombre',
  },
  es: {
    header: {
      about: 'Sobre mí',
      projects: 'Proyectos',
      skills: 'Habilidades',
      contact: 'Contacto',
      documentation: 'Documentación',
    },
    button: 'Cambiar a modo oscuro',
  },
};

// Configuration i18n
const i18n = createI18n({
  locale: localStorage.getItem('language') || 'fr', // Langue par défaut
  fallbackLocale: 'en', // Langue de secours
  messages,
});

export default i18n;
