import { createRouter, createWebHistory } from 'vue-router'
import HomePage from '../views/HomePage.vue'
import HomeV from '../views/HomeV.vue'
import Maintenance from '../views/Maintenance.vue'

const routes = [
  {
    path: '/',
    name: 'HomePage',
    component: () => import('@/views/Maintenance'),
    meta: {
      title: 'Site en construction - Thomas'
    }
  },
  {
    path: '/maintenance',
    name: 'Maintenance',
    component: Maintenance,
    meta: {
      title: 'Création en cours - Thomas'
    }
  },
  {
    path: '/welcomedev',
    name: 'Welcomedev',
    component: () => import('@/views/Welcome'),
    meta: {
      title: 'Création en cours - Thomas',
      transition: 'slide-right',
    },
  },
  {
    path: '/documentation',
    name: 'Documentation',
    component: () => import('@/views/Documentation'),
    meta: {
      title: 'Création en cours - Thomas',
      transition: 'slide-left',
    },
  },
  {
    path: '/:catchAll(.*)',
    redirect: '/maintenance'
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

// Navigation guard to set the page title
router.beforeEach((to, from, next) => {
  const defaultTitle = 'Thomas'; // Titre par défaut
  document.title = to.meta.title || defaultTitle;
  next();
})

export default router
